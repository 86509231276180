import {
  getDatabase,
  ref,
  query,
  get,
  set,
  update,
  push,
  child,
  orderByChild,
  equalTo,
  limitToFirst,
  DataSnapshot,
  onValue,
  onChildAdded,
  onChildRemoved,
} from "firebase/database";

import {
  getFirestore,
  collection,
  query as $query,
  where,
  and,
  or,
  orderBy,
  doc,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  limit,
  deleteField,
} from "firebase/firestore";

import _ from "lodash";
import helpers from "@/helpers/global";
import format from "date-fns/format";
// import addMinutes from "date-fns/addMinutes";

// const FS_COLLECTION_ASM_TEMPLATES = "assessment_templates";
const RDB_COLLECTION_TMP_FORMS = "temporary_forms";
const FORM_ID = "assessment_template";
const FORM_UUID_SEPARATOR = "#";

interface AssessmentTemplateForm {
  _id?: string;
  form_name?: string;
  objective?: string;
  scope?: string;
  languages_required?: string[];
  sections?: string[];
  values_and_traits?: any;
  oaic_archetypes?: any;
  acceptable_behaviors?: any;
  is_started_test?: boolean;
  uuid?: string;
  email?: string;
  creator?: string;
  form_id?: string;
  created_at?: string;
}

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    ignoreLoading: false,
    isNew: true,
    formId: "",
    formName: "",
    objective: "",
    hasValuesAndTraits: false,
    hasOaicArchetypes: false,
    hasAcceptableBehaviors: false,
    values: { sections: [] },
    items: [],
    existsTemplates: [],
    initFormDetails: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    ignoreLoading(
      state: { ignoreLoading: boolean },
      ignoreLoading: boolean
    ): any {
      state.ignoreLoading = ignoreLoading;
    },
    isNew(state: { isNew: boolean }, isNew: boolean): any {
      state.isNew = isNew;
    },
    formId(state: { formId: string }, formId: string): any {
      state.formId = formId;
    },
    formName(state: { formName: string }, formName: string): any {
      state.formName = formName;
    },
    objective(state: { objective: string }, objective: string): any {
      state.objective = objective;
    },
    values(state: { values: any }, values: { sections: any }): any {
      state.values = values;
    },
    items(state: { items: any }, items: { _id: string }): any {
      state.items = items;
    },
    existsTemplates(
      state: { existsTemplates: any },
      existsTemplates: { _id: string }
    ): any {
      state.existsTemplates = existsTemplates;
    },
    hasValuesAndTraits(
      state: { hasValuesAndTraits: boolean },
      hasValuesAndTraits: boolean
    ): any {
      state.hasValuesAndTraits = hasValuesAndTraits;
    },
    hasOaicArchetypes(
      state: { hasOaicArchetypes: boolean },
      hasOaicArchetypes: boolean
    ): any {
      state.hasOaicArchetypes = hasOaicArchetypes;
    },
    hasAcceptableBehaviors(
      state: { hasAcceptableBehaviors: boolean },
      hasAcceptableBehaviors: boolean
    ): any {
      state.hasAcceptableBehaviors = hasAcceptableBehaviors;
    },
    publicInvitedEmail(
      state: { publicInvitedEmail: string },
      publicInvitedEmail: string
    ): any {
      state.publicInvitedEmail = publicInvitedEmail;
    },
    publicInvitedUuid(
      state: { publicInvitedUuid: string },
      publicInvitedUuid: string
    ): any {
      state.publicInvitedUuid = publicInvitedUuid;
    },
    initFormDetails(
      state: { initFormDetails: any },
      initFormDetails: { uuid?: string }
    ): any {
      state.initFormDetails = initFormDetails;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    ignoreLoading(state: { ignoreLoading: boolean }): any {
      return state.ignoreLoading;
    },
    isNew(state: { isNew: boolean }): any {
      return state.isNew;
    },
    formId(state: { formId: string }): any {
      return state.formId;
    },
    formName(state: { formName: string }): any {
      return state.formName;
    },
    objective(state: { objective: string }): any {
      return state.objective;
    },
    values(state: { values: any }): any {
      return state.values;
    },
    items(state: { items: any[] }): any {
      return state.items;
    },
    existsTemplates(state: { existsTemplates: any[] }): any {
      return state.existsTemplates;
    },
    hasValuesAndTraits(state: { hasValuesAndTraits: boolean }): any {
      return state.hasValuesAndTraits;
    },
    hasOaicArchetypes(state: { hasOaicArchetypes: boolean }): any {
      return state.hasOaicArchetypes;
    },
    hasAcceptableBehaviors(state: { hasAcceptableBehaviors: boolean }): any {
      return state.hasAcceptableBehaviors;
    },
    publicInvitedEmail(state: { publicInvitedEmail: string }): any {
      return state.publicInvitedEmail;
    },
    publicInvitedUuid(state: { publicInvitedUuid: string }): any {
      return state.publicInvitedUuid;
    },
    initFormDetails(state: { initFormDetails: any }): any {
      return state.initFormDetails;
    },
  },
  actions: {
    check({ commit, rootState }: { commit: any; rootState: any }): boolean {
      const user = rootState.auth.user;
      if (!user) {
        commit(
          "auth/errorMessage",
          "You are not logged in. Please login and try again.",
          { root: true }
        );
        return false;
      }
      return true;
    },
    // clear temporary record to create new assessment template
    async cleanup(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      { callback }: { callback: any }
    ): Promise<any> {
      if (!dispatch("check")) return;

      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);
      const uuid = `${FORM_ID}${FORM_UUID_SEPARATOR}${email}`;

      const db = getDatabase();
      const path = RDB_COLLECTION_TMP_FORMS;
      const snapshots = await get(
        query(
          ref(db, path),
          orderByChild("uuid"),
          equalTo(uuid),
          limitToFirst(500)
        )
      );
      if (snapshots.size) {
        snapshots.forEach((child) => {
          if (child.exists()) {
            // debug
            // console.log(child.val(), "val()");
            // console.log(`${RDB_COLLECTION_TMP_FORMS}/${child.key}`);
            // if (child.key) return;

            // remove temporary form data
            /*
            const path = `${RDB_COLLECTION_TMP_FORMS}/${child.key}`;
            set(ref(db, path), null)
              .then(() => {
                // console.log("delete temp form success");
                // TODO: called in succces all delete children
                commit("initFormDetails", null);
                callback();
              })
              .catch((error) => {
                console.error(error);
                commit("initFormDetails", null);
                callback();
              });
            //*/

            // move to draft
            const path = `${RDB_COLLECTION_TMP_FORMS}/${child.key}`;
            update(ref(db, path), {
              uuid: `${FORM_ID}.draft${FORM_UUID_SEPARATOR}${email}`,
              created_at: helpers.now(),
            })
              .then(() => {
                commit("initFormDetails", null);
                callback();
              })
              .catch((error) => {
                console.error(error);
                commit("initFormDetails", null);
                callback();
              });
          }
        });

        // everytime when move temp to draft
        // clear items if over limit
        // uuid: `${FORM_ID}.draft${FORM_UUID_SEPARATOR}${email}`,
        const $uuid = `${FORM_ID}.draft${FORM_UUID_SEPARATOR}${email}`;
        const $snapshots = await get(
          query(
            ref(db, path),
            orderByChild("uuid"),
            equalTo($uuid),
            limitToFirst(500)
          )
        );
        let children: any[] = [];
        $snapshots.forEach((child) => {
          if (child.exists()) {
            const val = child.val();
            if (!val.created_at) return;
            val._key = child.key;
            val._id = child.key;
            children.push(val);
          }
        });
        children = _.sortBy(children, "created_at");
        children.reverse();
        // console.log(children, "children");

        const OVER_LIMIT = 5;
        if (children.length > OVER_LIMIT) {
          _.each(children.slice(OVER_LIMIT), (child) => {
            if (!child._key) return;
            const path = `${RDB_COLLECTION_TMP_FORMS}/${child._key}`;
            set(ref(db, path), null);
          });
        }
      } else {
        commit("initFormDetails", null);
        callback();
      }
    },
    async getInitFormDetails({
      commit,
      dispatch,
      rootState,
    }: {
      commit: any;
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      if (!dispatch("check")) return;

      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);
      const uuid = `${FORM_ID}${FORM_UUID_SEPARATOR}${email}`;

      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;

      const path = conlection;
      const snapshots = await get(
        query(
          ref(db, path),
          orderByChild("uuid"),
          equalTo(uuid),
          limitToFirst(1)
        )
      );
      let found = false;
      snapshots.forEach((child) => {
        if (found) return;
        found = child.exists();
        if (found) {
          commit("initFormDetails", child.val());
        }
      });
    },
    async new(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      initFormDetails?: AssessmentTemplateForm
    ): Promise<any> {
      // console.log("new()");
      // console.log(initFormDetails, "initFormDetails");
      if (!dispatch("check")) return;

      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);
      const uuid = `${FORM_ID}${FORM_UUID_SEPARATOR}${email}`;

      const HUMAN_SKILLS_ENABLED = true;

      let isNew = false;
      if (!initFormDetails) {
        isNew = true;
        initFormDetails = {
          form_name: "",
          objective: helpers.t("optional.Hiring_candidates"),
          scope: "personal",
          languages_required: ["English"],
          sections: [
            // "values_and_traits",
            // "OAIC_archetypes",
            // "human_skills",
            // "acceptable_behaviors",
            // "work_style_identifier",
          ],
          values_and_traits: null,
          oaic_archetypes: null,
          acceptable_behaviors: null,
          is_started_test: false,
          uuid: uuid,
          email: email,
          creator: email,
          form_id: FORM_ID,
          created_at: helpers.now(),
        };
        initFormDetails.sections = [];

        const DEFAULT_ENABLED_ALL = false;
        if (DEFAULT_ENABLED_ALL) {
          initFormDetails.sections.push("values_and_traits");
          initFormDetails.sections.push("OAIC_archetypes");
          HUMAN_SKILLS_ENABLED && initFormDetails.sections.push("human_skills");
          initFormDetails.sections.push("acceptable_behaviors");
          initFormDetails.sections.push("work_style_identifier");
        }
      }

      if (!initFormDetails.creator) {
        initFormDetails.creator = email;
      }
      if (!initFormDetails.email) {
        initFormDetails.email = email;
      }

      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;

      let found = false;
      let snapshot: DataSnapshot | null = null;
      // let snapshot;
      let formId;

      // NOTED: this process used for edit in temporary mode too

      commit("isNew", true);
      commit("loading", true);
      if (uuid) {
        const path = conlection;
        const snapshots = await get(
          query(
            ref(db, path),
            orderByChild("uuid"),
            equalTo(uuid),
            limitToFirst(1)
          )
        );
        snapshots.forEach((child) => {
          if (found) return;
          found = child.exists();
          snapshot = child;
        });
      }

      if (!snapshot) {
        const path = conlection;
        const key = push(child(ref(db), path)).key;
        const data = {
          uuid: uuid,
          email: email,
          creator: email,
          form_id: FORM_ID,
          created_at: helpers.now(),
        };
        formId = key;
        await set(ref(db, path + "/" + key), data)
          .then(() => {
            commit("loading", false);
            commit("formId", key);
            commit("error", null);
          })
          .catch((error) => {
            commit("loading", false);
            commit("error", error.message);
            console.error(error);
          });
      } else {
        formId = snapshot["key"];
        commit("loading", false);
        commit("formId", snapshot["key"]);
        commit("error", null);
      }

      const path = conlection;
      const q = query(ref(db, path + "/" + formId));

      // console.log(isNew, "isNew");
      // console.log(formId, "formId");

      snapshot = await get(q);
      if (formId && initFormDetails) {
        const path = conlection + "/" + formId;
        if (isNew) {
          await update(ref(db, path), initFormDetails);
        } else {
          // allowed attribute to update
          const attributes = [
            "uuid",
            "form_name",
            "objective",
            "scope",
            "sections",
            "values_and_traits",
            "values_and_traits_is_custom",
            "values_and_traits_custom_fields",
            "oaic_archetypes",
            "acceptable_behaviors",
            "acceptable_behaviors_is_custom",
            "acceptable_behaviors_custom_fields",
            "is_started_test",
            "extra_time",
            "auto_send_result",
            "open_ended_question",
            "redirect_url",
            "languages_required",
            "created_at",
            "values_and_traits_v_2",
            "values_and_traits_version_id",
          ];
          const updatedFormDetails = _.pick(initFormDetails, attributes);
          // console.log(updatedFormDetails, "updatedFormDetails");
          await set(ref(db, path), updatedFormDetails);
        }
        snapshot = await get(q);
      }

      // default values
      onValue(q, (snapshot) => {
        // console.log("onValue()");
        // console.log(snapshot.val(), "snapshot.val()");
        const values = snapshot.val();
        if (!values) return;
        if (values.values_and_traits) {
          const pendingReview = _.get(
            values,
            "ai_setup.values_and_traits.pending_review"
          );
          commit("hasValuesAndTraits", !pendingReview);
        }
        if (values.oaic_archetypes) {
          const pendingReview = _.get(
            values,
            "ai_setup.oaic_archetypes.pending_review"
          );
          commit("hasOaicArchetypes", !pendingReview);
        }
        if (values.acceptable_behaviors) {
          commit("hasAcceptableBehaviors", true);
        }
      });

      const targetKeys = [
        "acceptable_behaviors",
        "oaic_archetypes",
        "values_and_traits",
      ];
      onChildAdded(q, (snapshot) => {
        // console.log(`onChildAdded(${snapshot.key})`);
        if (_.indexOf(targetKeys, snapshot.key) !== -1) {
          const variableName = _.camelCase("has_" + snapshot.key);
          commit(variableName, true);
        }
      });
      onChildRemoved(q, (snapshot) => {
        // console.log(`onChildRemoved({snapshot.key})`);
        if (_.indexOf(targetKeys, snapshot.key) !== -1) {
          const variableName = _.camelCase("has_" + snapshot.key);
          commit(variableName, false);
        }
      });

      return snapshot;
    },
    async clone(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      formDetails: {
        routeId: string;
        teamId?: string;
        candidatesArecloned?: boolean;
      }
    ): Promise<any> {
      if (!dispatch("check")) return;
      commit("loading", true);
      const routeId = formDetails.routeId;
      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);
      const now = helpers.now();

      const $db = getFirestore();
      const q = $query(
        collection($db, "assessment_templates"),
        where("routeId", "==", routeId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      let found = false;
      let resultDoc: any;
      let canClone = false;
      for (const i in querySnapshot.docs) {
        const doc = querySnapshot.docs[i];
        // querySnapshot.forEach(async (doc: any) => {
        if (found) break;
        const data = doc.data();

        await dispatch(
          "team/getTeam",
          { teamId: data.team_id },
          { root: true }
        );
        const team = rootState.team.team;
        if (team && team.members) {
          canClone = _.isObject(
            // _.find(team.members, { email: email, role: "admin" })
            _.find(team.members, { email: email })
          );
        }

        if (!canClone) {
          const errorMsg = "Forbidden";
          commit("error", errorMsg);
          break;
        }
        resultDoc = doc;
        found = true;
        // });
      }

      if (!found || !resultDoc) {
        commit("loading", false);
        return;
      }
      const origId = resultDoc.id;
      let values = resultDoc.data();

      let teamId = formDetails.teamId;
      if (!teamId) {
        teamId = values.team_id;
      }

      const candidatesArecloned = formDetails.candidatesArecloned || false;

      const uuid = values.uuid;
      const routsDetails = {
        path: "/assessment-test/:id",
        uuid: uuid,
        data: values,
      };
      await dispatch("routes/create", routsDetails, {
        root: true,
      });
      const NewrouteId = rootState.routes.lastInsertId;

      let formName = values.form_name;
      let doCopyAll = true;
      if (!formDetails.teamId || formDetails.teamId == values.team_id) {
        formName += " (Copy)";
        doCopyAll = false;
      }

      let last_activity_at;
      if (doCopyAll && values.last_activity_at) {
        last_activity_at = values.last_activity_at;
      }

      if (!last_activity_at) {
        last_activity_at = now;
      }

      values = {
        ...values,
        routeId: NewrouteId,
        deleted: false,
        published_at: doCopyAll ? values.published_at : now,
        created_at: doCopyAll ? values.created_at : now,

        // copy values
        form_name: formName,
        team_id: teamId,
        scope: "personal",
        creator: email,
        email: email,

        // stat values
        candidate_total: doCopyAll ? values.candidate_total : 0,
        progress: doCopyAll ? values.progress : 0,
        last_activity_at: last_activity_at,
      };

      // Fix NaN values
      values.candidate_total = isNaN(values.candidate_total)
        ? 0
        : values.candidate_total;
      values.progress = isNaN(values.progress) ? 0 : values.progress;

      values.is_copied = true;
      values.copied_by = rootState.user.user.email;
      values.copied_at = helpers.now();

      // @fixed default values
      values.candidate_total = Number(values.candidate_total);
      values.progress = Number(values.progress);
      values.last_activity_at = String(values.last_activity_at);

      // move data `realtime database`to `firebase`
      const docRef = await addDoc(
        collection($db, "assessment_templates"),
        values
      );
      const newFormId = docRef.id;

      commit("ignoreLoading", true);
      await setDoc(
        doc($db, "routes", NewrouteId),
        {
          uuid: newFormId,
        },
        { merge: true }
      );

      if (candidatesArecloned) {
        const q = $query(
          collection($db, "assessments"),
          where("form_id", "==", origId),
          orderBy("created_at", "desc")
        );

        const querySnapshot = await getDocs(q);
        for (const i in querySnapshot.docs) {
          const document = querySnapshot.docs[i];
          // console.log(document.data(), "document.data()");

          const newValues = _.cloneDeep(document.data());
          const newDocId = `${newFormId}-${helpers.emailKey(newValues.email)}`;
          newValues.form_id = newFormId;
          newValues.is_copied = true;
          newValues.copied_by = rootState.user.user.email;
          newValues.copied_at = helpers.now();

          await setDoc(doc($db, "assessments", newDocId), newValues, {
            merge: false,
          });
        }
      }

      commit("ignoreLoading", false);
      commit("loading", false);
      return docRef;
    },
    async edit(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      formDetails: { routeId: string }
    ): Promise<any> {
      if (!dispatch("check")) return;
      commit("loading", true);
      const routeId = formDetails.routeId;
      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);

      const $db = getFirestore();
      const q = $query(
        collection($db, "assessment_templates"),
        where("routeId", "==", routeId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      let found = false;
      let resultDoc: any;
      for (const i in querySnapshot.docs) {
        const doc = querySnapshot.docs[i];
        // querySnapshot.forEach(async (doc: any) => {
        if (found) return;
        const data = doc.data();

        /*
        let canEdit = false;
        await dispatch(
          "team/getTeam",
          { teamId: data.team_id },
          { root: true }
        );
        const team = rootState.team.team;
        if (team && team.members) {
          canEdit = _.isObject(
            // _.find(team.members, { email: email, role: "admin" })
            _.find(team.members, { email: email })
          );
        }
        //*/
        const canEdit = data.creator == email;

        // const isOwner = data.email == email || data.creator == email;

        if (!canEdit) {
          const errorMsg = "Forbidden";
          commit("error", errorMsg);
          return;
        }
        resultDoc = doc;
        found = true;
        // });
      }

      // @todo clone current data to temporary process
      if (!found || !resultDoc) {
        commit("loading", false);
        return;
      }

      commit("ignoreLoading", true);

      // @todo sent data to new for init data
      const doc = await dispatch("new", resultDoc.data());
      commit("isNew", false);
      commit("ignoreLoading", false);
      commit("loading", false);
      return doc;
    },
    async load(
      {
        commit,
        dispatch,
        getters,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      {
        formId,
        section,
        doNotMergeTemp,
      }: { formId: string; section?: string; doNotMergeTemp?: boolean }
    ): Promise<any> {
      // console.log(`load(${formId})`, section);
      doNotMergeTemp = Boolean(doNotMergeTemp);
      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;
      const path = `${conlection}/${formId}`;
      await get(child(ref(db), path))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            commit("formId", formId);
            commit("values", snapshot.val());
            if (section) {
              // await dispatch(
              //   "assessmentSection/loadSetupByNames",
              //   [
              //     section,
              //     // "values_and_traits",
              //     // "OAIC_archetypes",
              //     // "acceptable_behaviors",
              //     // "work_style_identifier",
              //   ],
              //   {
              //     root: true,
              //   }
              // );
              await dispatch("getList", { page: 1 });
              const items = getters.items;
              // console.log(items, "items");
              // console.group("form_name");
              const options = _.filter(items, (item) => {
                // console.log("form_name: " + item.form_name);
                const sections = item.sections || [];
                const found = _.indexOf(sections, section) !== -1;
                if (!found) return false;
                type keys = keyof typeof item;
                const $section = section.toLowerCase();
                const key = $section as keys;
                return !_.isEmpty(item[key]);
              });
              // console.groupEnd();

              // console.log(options.length, "existsTemplates.length");
              // console.log(options, "existsTemplates");

              // uuid: `${FORM_ID}.draft${FORM_UUID_SEPARATOR}${email}`,
              if (!doNotMergeTemp) {
                const user = rootState.auth.user;
                const email = helpers.filterEmail(user.email);
                const uuid = `${FORM_ID}.draft${FORM_UUID_SEPARATOR}${email}`;

                const db = getDatabase();
                const path = RDB_COLLECTION_TMP_FORMS;
                const snapshots = await get(
                  query(
                    ref(db, path),
                    orderByChild("uuid"),
                    equalTo(uuid),
                    limitToFirst(500)
                  )
                );
                let children: any[] = [];
                snapshots.forEach((child) => {
                  if (child.exists()) {
                    const val = child.val();
                    if (!val.created_at) return;
                    val._key = child.key;
                    val._id = child.key;
                    children.push(val);
                  }
                });
                children = _.sortBy(children, "created_at");
                children.reverse();
                if (children.length) {
                  // console.log(children, "children");
                  // console.log(children[0], "children[0]");
                  const firstChild = children[0];
                  const sections = firstChild.sections || [];
                  const found = _.indexOf(sections, section) !== -1;
                  if (found) {
                    // console.log(sections, "sections");
                    // console.log(section, "section");
                    const variable = section.toLowerCase();
                    if (
                      Object.prototype.hasOwnProperty.call(firstChild, variable)
                    ) {
                      options.unshift(firstChild);
                    }
                  }
                }
              }

              // console.log(options.length, "options.length");
              // console.log(options, "options");
              commit("existsTemplates", options);
            }
          } else {
            commit("error", `Invaid form ID: ${formId}`);
          }
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async loadFromExistsTemplate(
      {
        commit,
        dispatch,
      }: {
        commit: any;
        dispatch: any;
      },
      {
        formId,
        selectedExistsTemplate,
        section,
      }: {
        formId: string;
        selectedExistsTemplate: { _id?: string };
        section: string;
      }
    ): Promise<any> {
      // console.log("loadFromExistsTemplate()");
      commit("loading", true);
      type keys = keyof typeof selectedExistsTemplate;
      const $section = section.toLowerCase();
      const key = $section as keys;
      const sectionValues = selectedExistsTemplate[key];
      const savedValues: any = {};
      savedValues[key] = sectionValues;

      // Copy custom fields
      // examples: values_and_traits_is_custom, values_and_traits_custom_fields
      for (const attr in selectedExistsTemplate) {
        if (attr.indexOf(key + "_") === 0) {
          savedValues[attr] = _.get(selectedExistsTemplate, attr);
        }
      }

      // Must be remove form temp form too.
      if (section == "acceptable_behaviors") {
        if (
          !Object.prototype.hasOwnProperty.call(
            savedValues,
            "acceptable_behaviors_is_custom"
          )
        ) {
          savedValues.acceptable_behaviors_is_custom = false;
        }
        if (
          !Object.prototype.hasOwnProperty.call(
            savedValues,
            "acceptable_behaviors_custom_fields"
          )
        ) {
          savedValues.acceptable_behaviors_custom_fields = null;
        }
      }

      if (section == "values_and_traits") {
        if (!_.get(selectedExistsTemplate, "values_and_traits_version_id")) {
          savedValues["values_and_traits_version_id"] = null;
        }
      }

      // console.log(savedValues, "savedValues");

      const db = getDatabase();
      const path = `/temporary_forms/${formId}`;
      await update(ref(db, path), savedValues)
        .then(async () => {
          // Data saved successfully!
          await dispatch("load", { formId: formId });
          commit("error", false);

          commit("loading", false);
        })
        .catch((error) => {
          // The write failed...
          console.error(error);
          commit("error", error.message);
          commit("loading", false);
        });
    },
    async proceedWithNewSetup(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      {
        formId,
        section,
      }: {
        formId: string;
        section: string;
      }
    ): Promise<any> {
      commit("loading", true);

      // Can't declare variable in switch case
      /*
      let valueAlignmentVersionId = null;
      if (section == "values_and_traits") {
        // @fixed get value alignment version id
        const fs = getFirestore();
        const q = $query(
          collection(fs, "value_alignment_versions"),
          where("enabled", "==", true),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size) {
          const document = querySnapshot.docs[0];
          valueAlignmentVersionId = document.id;
        }
      }
      //*/

      let valueAlignmentVersionId = null;
      if (section == "values_and_traits") {
        // console.log("before:");
        await dispatch("assessmentSection/initValuesAndTraits", null, {
          root: true,
        });
        // console.log("after:");
        // console.log(rootState, "rootState");
        valueAlignmentVersionId = _.get(
          rootState,
          "assessmentSection.valuesAndTraitsVersionId"
        );
      }

      const savedValues: any = {};
      switch (section) {
        case "values_and_traits":
          savedValues.values_and_traits = null;
          savedValues.values_and_traits_custom_fields = null;
          savedValues.values_and_traits_is_custom = null;
          savedValues.values_and_traits_v_2 = null;
          savedValues.values_and_traits_version_id = valueAlignmentVersionId;
          break;
        case "OAIC_archetypes":
          savedValues.oaic_archetypes = null;
          break;
        case "acceptable_behaviors":
          savedValues.acceptable_behaviors = null;
          savedValues.acceptable_behaviors_custom_fields = null;
          savedValues.acceptable_behaviors_is_custom = null;
          break;
      }

      const db = getDatabase();
      const path = `/temporary_forms/${formId}`;
      await update(ref(db, path), savedValues)
        .then(async () => {
          // Data saved successfully!
          await dispatch("load", { formId: formId });
          commit("error", false);

          commit("loading", false);
        })
        .catch((error) => {
          // The write failed...
          console.error(error);
          commit("error", error.message);
          commit("loading", false);
        });

      commit("loading", false);
    },
    // @note database: temporary_forms
    async saveFormDetails(
      { commit }: { commit: any },
      formDetailsDto: {
        _id: string;
        formName: string;
        callback: any;
      }
    ): Promise<any> {
      commit("loading", true);
      let _id,
        callback = formDetailsDto.callback,
        values = {};
      if (Object.prototype.hasOwnProperty.call(formDetailsDto, "_id")) {
        _id = formDetailsDto._id;
        if (typeof callback != "function") {
          callback = helpers.noop;
        }
        values = { ..._.omit(formDetailsDto, ["_id", "callback"]) };
        values = helpers.transformValuesDto(values);
      }

      const db = getDatabase();
      const path = `/temporary_forms/${_id}`;
      update(ref(db, path), values)
        .then(() => {
          // Data saved successfully!
          commit("error", false);
          commit("loading", false);
          if (typeof callback == "function") {
            callback();
          }
        })
        .catch((error) => {
          // The write failed...
          console.error(error);
          commit("error", error.message);
          commit("loading", false);
        });
    },
    // @note database: temporary_forms
    async saveSectionAnswers(
      { commit, dispatch }: { commit: any; dispatch: any },
      sectionAnswersDto: {
        _id: string;
        section: any;
        answers: any;
        callback?: any;
      }
    ): Promise<any> {
      // console.log("saveSectionAnswers()");
      // console.log(sectionAnswersDto, "sectionAnswersDto");
      commit("loading", true);

      // build answers for DTO (Data Transfer Object)
      const name = _.camelCase(sectionAnswersDto.section.name);
      const values: any = {
        _id: sectionAnswersDto._id,
        callback: sectionAnswersDto.callback,
      };
      values[name] = {};

      const answersMap: any = {};
      for (const i in sectionAnswersDto.answers) {
        answersMap[sectionAnswersDto.answers[i].id] =
          sectionAnswersDto.answers[i].value;
      }

      for (const group in sectionAnswersDto.section.scenarios) {
        values[name][group] = {};
        const scenario = sectionAnswersDto.section.scenarios[group];
        const questions = scenario.questions;
        for (const qid in questions) {
          const questionId = questions[qid].id;
          if (Object.prototype.hasOwnProperty.call(answersMap, questionId)) {
            values[name][group][questionId] = answersMap[questionId];
          }
        }
      }
      const customAttributes = [
        "valuesAndTraitsIsCustom",
        "valuesAndTraitsCustomFields",
        "acceptableBehaviorsIsCustom",
        "acceptableBehaviorsCustomFields",
        "valuesAndTraitsV2",
        "valuesAndTraitsVersionId",
      ];
      for (const i in customAttributes) {
        const customAttribute = customAttributes[i];
        if (
          Object.prototype.hasOwnProperty.call(
            sectionAnswersDto,
            customAttribute
          )
        ) {
          values[customAttribute] = _.get(sectionAnswersDto, customAttribute);
        }
      }

      await dispatch("saveFormDetails", values);
    },
    // @note database: temporary_forms
    async toggleSection(
      { commit, state }: { commit: any; state: any },
      sectionName: string
    ): Promise<any> {
      const formId = state.formId;
      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;
      const path = `${conlection}/${formId}`;

      get(child(ref(db), path))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const values = snapshot.val();
            if (!Object.prototype.hasOwnProperty.call(values, "sections")) {
              values.sections = [];
            }
            const index = _.indexOf(values.sections, sectionName);
            if (index === -1) {
              values.sections.push(sectionName);
            } else {
              _.remove(values.sections, (name) => {
                return name == sectionName;
              });
            }

            const savedValues = {
              sections: values.sections,
            };
            const path = `/temporary_forms/${formId}`;
            update(ref(db, path), savedValues)
              .then(() => {
                // Data saved successfully!
                commit("error", false);
                commit("loading", false);
              })
              .catch((error) => {
                // The write failed...
                console.error(error);
                commit("error", error.message);
                commit("loading", false);
              });
          } else {
            commit("error", `Invaid form ID: ${formId}`);
          }
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async saveCoreValues(
      { commit, state }: { commit: any; state: any },
      coreValues: string[]
    ): Promise<any> {
      const formId = state.formId;
      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;
      const path = `${conlection}/${formId}`;

      get(child(ref(db), path))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const savedValues = {
              core_values: coreValues,
            };
            const path = `/temporary_forms/${formId}`;
            update(ref(db, path), savedValues)
              .then(() => {
                // Data saved successfully!
                commit("error", false);
                commit("loading", false);
              })
              .catch((error) => {
                // The write failed...
                console.error(error);
                commit("error", error.message);
                commit("loading", false);
              });
          }
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async createAssessmentTemplateFromTempForm(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      {
        formId,
        callback,
        extraTime,
        autoSendResult,
        openEndedQuestion,
        redirectUrl,
      }: {
        formId: string;
        callback: any;
        extraTime?: number;
        autoSendResult: boolean;
        openEndedQuestion: boolean;
        redirectUrl?: string;
      }
    ): Promise<any> {
      // console.log("createAssessmentTemplateFromTempForm()");
      // console.log(redirectUrl, "redirectUrl");
      // if (redirectUrl) return;
      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;
      const path = `${conlection}/${formId}`;
      const now = helpers.now();

      if (typeof callback != "function") {
        callback = helpers.noop;
      }

      commit("loading", true);
      get(child(ref(db), path))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let values = snapshot.val();
            if (!values.creator) {
              const user = rootState.auth.user;
              const email = helpers.filterEmail(user.email);
              values.creator = email;
            }

            const uuid = `${formId}`;
            const routsDetails = {
              path: "/assessment-test/:id",
              uuid: uuid,
              data: values,
            };

            await dispatch("routes/create", routsDetails, {
              root: true,
            });
            const routeId = rootState.routes.lastInsertId;
            const team = rootState.team.team;
            let teamId = "";
            if (team) {
              teamId = team._id;
            }
            values = {
              ...values,
              team_id: teamId,
              routeId: routeId,
              deleted: false,
              published_at: now,
              extra_time: Number(extraTime),
              auto_send_result: autoSendResult,
              open_ended_question: openEndedQuestion,
              redirect_url: redirectUrl,
            };

            // @todo transfrom data. what do we want when query

            // move data `realtime database`to `firebase`
            const $db = getFirestore();
            const docRef = await addDoc(
              collection($db, "assessment_templates"),
              values
            );

            // update route uuid with real identity
            await setDoc(
              doc($db, "routes", routeId),
              {
                uuid: docRef.id,
              },
              { merge: true }
            );

            // remove temporary form data
            set(ref(db, path), null)
              .then(() => {
                commit("loading", false);
                callback();
              })
              .catch((error) => {
                commit("loading", false);
                console.error(error);
              });
          } else {
            commit("loading", false);
            commit("error", `Invaid form ID: ${formId}`);
          }
        })
        .catch((error) => {
          commit("loading", false);
          console.error(error);
          commit("error", error);
        });
    },
    async updateAssessmentTemplateFromTempForm(
      { commit }: { commit: any },
      {
        formId,
        routeId,
        callback,
        extraTime,
        autoSendResult,
        openEndedQuestion,
        redirectUrl,
      }: {
        formId: string;
        routeId: string;
        callback: any;
        extraTime?: number;
        autoSendResult: boolean;
        openEndedQuestion: boolean;
        redirectUrl?: string;
      }
    ): Promise<any> {
      // console.log("updateAssessmentTemplateFromTempForm()");
      // console.log(redirectUrl, "redirectUrl");
      // if (redirectUrl) return;
      commit("loading", true);
      const db = getDatabase();
      const conlection = RDB_COLLECTION_TMP_FORMS;
      const path = `${conlection}/${formId}`;
      const now = helpers.now();

      // firestore: assessment_template
      const $db = getFirestore();
      const q = $query(
        collection($db, "assessment_templates"),
        where("routeId", "==", routeId)
      );
      const querySnapshot = await getDocs(q);
      let $doc;
      let $formId = "";
      for (const i in querySnapshot.docs) {
        $doc = querySnapshot.docs[i];
        $formId = $doc.id;
      }

      if (!$formId) {
        commit("loading", false);
        commit("error", `Not found route: ${routeId}`);
        return;
      }

      if (typeof callback != "function") {
        callback = helpers.noop;
      }
      if ($formId) {
        get(child(ref(db), path))
          .then(async (snapshot) => {
            if (snapshot.exists()) {
              // prevent deep merge object values
              const unsetData = {
                values_and_traits: deleteField(),
                values_and_traits_custom_fields: deleteField(),
                values_and_traits_is_custom: deleteField(),
                values_and_traits_v_2: deleteField(),
              };
              await setDoc(
                doc($db, "assessment_templates", $formId),
                unsetData,
                {
                  merge: true,
                }
              );

              let values = snapshot.val();
              values = {
                ...values,
                extra_time: Number(extraTime),
                auto_send_result: autoSendResult,
                open_ended_question: openEndedQuestion,
                redirect_url: redirectUrl,
                updated_at: now,
                published_at: now,
              };

              await setDoc(doc($db, "assessment_templates", $formId), values, {
                merge: true,
              });

              // remove temporary form data
              set(ref(db, path), null)
                .then(() => {
                  commit("loading", false);
                  callback();
                })
                .catch((error) => {
                  commit("loading", false);
                  console.error(error);
                });
            } else {
              commit("loading", false);
              commit("error", `Invaid form ID: ${formId}`);
            }
          })
          .catch((error) => {
            commit("loading", false);
            console.error(error);
            commit("error", error);
          });
      }

      commit("loading", false);
    },
    async getList(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      criteria: { page: number }
    ): Promise<any> {
      if (!dispatch("check")) return;

      commit("loading", true);
      const email = rootState.auth.user ? rootState.auth.user.email : "";
      const uuid = "assessment_template#" + email;
      // console.log(criteria, "criteria");
      let page = criteria.page;
      if (!page) page = 1;

      // Team(s) Permission
      const TEAM_COMPATIBLE_V1 = false;
      let filter = null;
      if (TEAM_COMPATIBLE_V1) {
        await dispatch("team/getTeamIds", null, { root: true });
        const teamIds = rootState.team.teamIds;
        if (teamIds.length == 0) {
          filter = and(
            where("uuid", "==", uuid),
            where("deleted", "==", false)
          );
        } else {
          filter = and(
            or(where("uuid", "==", uuid), where("team_id", "in", teamIds)),
            where("deleted", "==", false)
          );
        }
      } else {
        const teamId = rootState.user.user.teamId || "";
        filter = and(
          // where("uuid", "==", uuid),
          where("team_id", "==", teamId),
          // and(
          or(
            where("scope", "==", "shared"),
            and(where("scope", "==", "personal"), where("creator", "==", email))
          ),
          // ),
          where("deleted", "==", false)
        );
      }

      const $db = getFirestore();
      const q = $query(
        collection($db, "assessment_templates"),
        filter,
        orderBy("created_at", "desc")
      );
      const querySnapshot = await getDocs(q);
      const items: any[] = [];
      for (const i in querySnapshot.docs) {
        const doc = querySnapshot.docs[i];
        const data = doc.data();

        let last_activity_at = data.last_activity_at
          ? data.last_activity_at
          : data.created_at;
        last_activity_at = format(
          new Date(last_activity_at),
          "yyyy-MM-dd HH:mm:ss"
        );

        // fix NaN
        data.candidate_total = isNaN(data.candidate_total)
          ? 0
          : data.candidate_total;
        data.progress = isNaN(data.progress) ? 0 : data.progress;

        const defaults = {
          candidate_total: 0,
          progress: 0,
          last_activity_at: last_activity_at,
        };
        items.push({ _id: doc.id, ...defaults, ...data });
      }
      commit("items", items);

      commit("loading", false);
    },
    async publicInvite(
      { commit }: { commit: any },
      formDetailsDto: {
        formId: string;
        firstname: string;
        lastname: string;
        publicUrl: string;
        email?: string;
      }
    ): Promise<any> {
      commit("loading", true);
      if (!formDetailsDto.email) {
        const domain = "temp.happily.ai";
        const firstname = formDetailsDto.firstname.toLowerCase();
        const lastname = formDetailsDto.lastname.toLowerCase();
        // const fullname = firstname + "." + lastname + "-" + helpers.guid();
        const fullname = firstname + "." + lastname;
        formDetailsDto.email = `${fullname}@${domain}`;
      }
      commit("publicInvitedEmail", formDetailsDto.email);

      const now = helpers.now();
      const _email = helpers.filterPath(
        helpers.filterEmail(formDetailsDto.email)
      );
      const _id = `${formDetailsDto.formId}-${_email}`;

      // bogus invitation
      commit(
        "assessment/invitation",
        {
          formId: formDetailsDto.formId,
          email: formDetailsDto.email,
        },
        { root: true }
      );

      // @ailas assessment/createFromInvitation
      const $db = getFirestore();
      const docRef = doc($db, "assessment_templates", formDetailsDto.formId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const formTemplate = docSnap.data();

        // creator
        const emailKey = helpers.filterPath(
          helpers.filterEmail(formTemplate.email || formTemplate.creator)
        );
        const path = `users/${emailKey}`;
        const creator = (await get(query(ref(getDatabase(), path)))).val();

        const formDetails = {
          email: formDetailsDto.email,
          form_id: formDetailsDto.formId,
          form_name: formTemplate.form_name,
          firstname: formDetailsDto.firstname,
          lastname: formDetailsDto.lastname,
          creator: creator.email,
          company_name: String(creator.companyName),
          action_url: formDetailsDto.publicUrl,
          status: "INVITED",
          invited_at: now,
          last_accessed_at: now,
          created_at: now,
          updated_at: now,
          deleted: false,
        };
        // await setDoc(doc($db, "assessments", _id), formDetails);
        await setDoc(doc($db, "assessments", _id), formDetails, {
          merge: true,
        });
        const $formId = formDetails.form_id;
        const $emailKey = helpers.filterPath(
          helpers.filterEmail(formDetails.email)
        );
        const publicInvitedUuid = $formId + "-" + $emailKey;
        commit("publicInvitedUuid", publicInvitedUuid);

        // update candidate total
        const q = $query(
          collection($db, "assessments"),
          where("form_id", "==", formDetailsDto.formId)
        );
        const querySnapshot = await getDocs(q);

        await setDoc(
          doc($db, "assessment_templates", formDetailsDto.formId),
          { candidate_total: querySnapshot.size },
          {
            merge: true,
          }
        );
      }
      commit("loading", false);
    },
    async _invite(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      formDetailsDto: {
        formId: string;
        email: string;
        routeId?: string;
        publicUrl?: string;
        useSameLink?: boolean;
      }
    ): Promise<any> {
      commit("loading", true);

      const $db = getFirestore();

      const creator = rootState.auth.user;

      const companyName = creator.companyName || creator.company_name;
      const createdAt = helpers.now();

      let formDetails: any = {
        ...formDetailsDto,
        creator: creator.email,
        companyName: companyName,
        createdAt: createdAt,
      };

      const test = formDetails.test || false;

      const formId = formDetailsDto.formId;
      const emailKey = helpers.filterPath(
        helpers.filterEmail(formDetailsDto.email)
      );
      const uuid = `${formId}-${emailKey}`;
      const routsDetails = {
        path: "/assessment-test/:id/:params",
        uuid: uuid,
        data: formDetails,
      };

      let routeId = "";
      const useSameLink = formDetailsDto.useSameLink || false;
      if (useSameLink) {
        delete formDetails.useSameLink;
        const q = $query(
          collection($db, "routes"),
          where("uuid", "==", routsDetails.uuid),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc: any) => {
          routeId = doc.id;
        });
      }

      if (!routeId.length) {
        await dispatch("routes/create", routsDetails, {
          root: true,
        });
        routeId = rootState.routes.lastInsertId;
      }

      // change public url to private url
      let publicUrl = formDetails.publicUrl;
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const routerPath = "assessment-test";
      const privateUrl = `${baseUrl}/${routerPath}/${routeId}`;
      publicUrl = privateUrl;
      formDetails.publicUrl = publicUrl;

      // update route uuid with real identity
      await setDoc(
        doc($db, "routes", routeId),
        {
          data: {
            publicUrl: formDetails.publicUrl,
          },
          updated_at: helpers.now(),
        },
        { merge: true }
      );

      formDetails = { ...formDetails, routeId: routeId };

      const db = getDatabase();

      // send the request to server for get OTP (Invitation)
      const otpPath = `inviteweb`;
      const otpKey = push(child(ref(db), otpPath)).key;

      let result = false;
      await set(ref(db, otpPath + "/" + otpKey), formDetails)
        .then(async () => {
          // Send Test Email
          if (test === true) return;

          await dispatch("assessment/createFromInvitation", formDetails, {
            root: true,
          });

          // update candidate total
          const $db = getFirestore();
          const q = $query(
            collection($db, "assessments"),
            where("form_id", "==", formId)
          );
          const querySnapshot = await getDocs(q);

          const updatedTemplate: any = {
            candidate_total: querySnapshot.size,
          };

          // used for next time in this assessment
          if (formDetails.htmlBody) {
            updatedTemplate.advancedInviteEmailTemplate = {
              htmlBody: formDetails.htmlBody,
              subject: formDetails.subject,
              sender: formDetails.sender,
            };
          }

          await setDoc(
            doc($db, "assessment_templates", formDetailsDto.formId),
            updatedTemplate,
            {
              merge: true,
            }
          );

          // commit("setEmail", email);
          commit("error", null);
          result = true;
        })
        .catch((error) => {
          // The write failed...
          console.log(error, "error");
          commit("error", error.message);
        });

      commit("loading", false);

      return result;
    },
    async invite(
      { dispatch }: { commit: any; dispatch: any; rootState: any },
      formDetailsDto: {
        formId: string;
        email: string;
        routeId?: string;
        publicUrl?: string;
        useSameLink?: boolean;
        recipients?: any[];
      }
    ): Promise<any> {
      if (Object.prototype.hasOwnProperty.call(formDetailsDto, "recipients")) {
        const recipients = formDetailsDto.recipients || [];
        const newFormDetailsDto: any = _.omit(formDetailsDto, "recipients");
        for (const i in recipients) {
          const recipient = recipients[i];
          newFormDetailsDto.firstname = recipient.firstname;
          newFormDetailsDto.lastname = recipient.lastname;
          newFormDetailsDto.email = recipient.email;
          await dispatch("_invite", newFormDetailsDto);
        }
        return true;
      } else {
        return await dispatch("_invite", formDetailsDto);
      }
    },
    async updateLastActivityAt(
      { commit }: { commit: any },
      formId: string
    ): Promise<any> {
      const now = helpers.now();
      const $db = getFirestore();
      await setDoc(
        doc($db, "assessment_templates", formId),
        {
          last_activity_at: now,
          updated_at: now,
        },
        { merge: true }
      );
      commit("error", "");
    },
    async updateProgress(
      { commit }: { commit: any },
      formId: string
    ): Promise<any> {
      if (!formId) {
        commit("error", "Invalid form ID: " + formId);
        return;
      }

      commit("loading", true);
      const now = helpers.now();
      const $db = getFirestore();
      const q = $query(
        collection($db, "assessments"),
        where("form_id", "==", formId),
        orderBy("created_at", "desc")
      );
      const querySnapshot = await getDocs(q);
      const total = querySnapshot.size;
      let count = 0;
      let progress = 0;
      let foundInvited = false;
      querySnapshot.forEach(async (doc: any) => {
        const data = doc.data();
        const point = data.status == "COMPLETED" ? 1 : 0;
        foundInvited = foundInvited || data.status == "INVITED";
        count += point;
      });

      if (total > 0) {
        progress = Math.round((count / total) * 100);
      }

      // update `candidate_total` for alter delete candidate too!
      const formDetails = {
        candidate_total: total,
        is_started_test: total > 0 && !foundInvited,
        progress: progress,
        updated_at: now,
      };
      await setDoc(doc($db, "assessment_templates", formId), formDetails, {
        merge: true,
      });

      commit("loading", false);
    },
    async softDelete(
      { commit }: { commit: any },
      assementTemplateDetails: { _id: string }
    ): Promise<any> {
      commit("loading", true);
      const _id = assementTemplateDetails._id;
      const $db = getFirestore();
      await setDoc(
        doc($db, "assessment_templates", _id),
        { deleted: true, deleted_at: helpers.now() },
        {
          merge: true,
        }
      );
      const q = $query(
        collection($db, "assessments"),
        where("form_id", "==", _id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (document: any) => {
        await setDoc(
          doc($db, "assessments", document.id),
          { deleted: true, deleted_at: helpers.now() },
          {
            merge: true,
          }
        );
      });
      commit("loading", false);
    },
    async startTest(
      { commit }: { commit: any },
      assementTemplateDetails: { _id: string }
    ): Promise<any> {
      commit("loading", true);
      const _id = assementTemplateDetails._id;
      const $db = getFirestore();
      await setDoc(
        doc($db, "assessment_templates", _id),
        { is_started_test: true },
        {
          merge: true,
        }
      );
      commit("loading", false);
    },
  },
};
